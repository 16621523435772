<!-- 视频设备状态 -->
<template>
  <div id="video-device">
    <div class="tree">
      <div class="tree-title">变配电站</div>
      <el-tree
        ref="tree"
        :default-expand-all="false"
        :show-checkbox="false"
        :data="treeData"
        :check-on-click-node="false"
        :props="defaultProps"
        node-key="id"
        @node-click="handleCheck"
      ></el-tree>
    </div>
    <div class="main">
      <div class="flex-vertical-center">
        <div class="title">
          <img src="../../assets/images/LTicon.png" alt="" />
          <span>视频设备状态</span>
        </div>
        <el-checkbox v-model="checked">只显示离线状态</el-checkbox>
      </div>
      <div class="content">
        <el-table class="table" :data="tableData">
          <el-table-column align="center" prop="name" label="序号">
          </el-table-column>
          <el-table-column align="center" prop="zero" label="配电站名称">
          </el-table-column>
          <el-table-column align="center" prop="one" label="设备名称">
          </el-table-column>
          <el-table-column align="center" prop="two" label="设备序列号">
          </el-table-column>
          <el-table-column align="center" prop="three" label="状态">
          </el-table-column>
        </el-table>
        <div class="pagination">
        <button class="home-page">首页</button>
        <el-pagination
          :current-page="requestParam.pageNum"
          :page-sizes="[10, 20, 30, 40]"
          layout="prev, pager, next, sizes,slot"
          prev-text="上一页"
          next-text="下一页"
          :total="total"
          @size-change="changeSize"
          @current-change="changeCurrent"
        >
        </el-pagination>
        <button class="tail-page">尾页</button>
        <div class="jump-pagination">
          <span class="text">跳至</span>
          <el-input size="mini" class="pagination-input"></el-input>
          <span class="text">页</span>
          <button class="confirm">确定</button>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import { nextTick } from "vue";
export default {
  name: "",

  data() {
    return {
      formData: {
        name: "",
        type: [],
      },
      tableData: [
        {
          name: "1",
          zero: "总部A配电室",
          one: "配电室球机",
          two: "E10574175",
          three: "在线",
        },
        {
          name: "2",
          zero: "总部A配电室",
          one: "配电室球机",
          two: "E10574175",
          three: "在线",
        },
        {
          name: "3",
          zero: "总部A配电室",
          one: "配电室球机",
          two: "E10574175",
          three: "在线",
        },
      ],
      requestParam: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      checkedNodes: [],
      defaultProps: {
        children: "children",
        id: "id",
        label: "label",
      },
      treeData: [
        {
          id: 1,
          label: "配电室",
          children: [
            {
              id: 11,
              label: "高压侧",
            },
            {
              id: 12,
              label: "低压侧",
            },
            {
              id: 13,
              label: "变压器",
            },
            {
              id: 14,
              label: "直流屏",
            },
          ],
        },
        {
          id: 2,
          label: "强变",
        },
        {
          id: 3,
          label: "柱变",
        },
        {
          id: 4,
          label: "强电间",
          children: [
            {
              id: 15,
              label: "高压侧",
            },
            {
              id: 16,
              label: "低压侧",
            },
            {
              id: 17,
              label: "变压器",
            },
            {
              id: 18,
              label: "直流屏",
            },
          ],
        },
      ],
    };
  },

  mounted() {},

  methods: {
    handleCheck(checkedNodes, event) {
      this.checkedNodes = checkedNodes;
      console.log(this.checkedNodes);
    },
  },
};
</script>
<style scoped>
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.flex-vertical-center {
  display: flex;
  align-items: center;
}
#video-device {
  width: 100%;
  min-height: 781px;
  background: url("../../assets/images/routerImg.png") no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
  box-sizing: border-box;
  display: flex;
}
/* tree */
.tree :deep() .el-tree {
  width: 240px;
  color: #aed6ff;
  background-color: rgba(255, 255, 255, 0);
  padding: 0 0 10px 21px;
  box-sizing: border-box;
}
.tree-title {
  font-weight: 700;
  color: #d8e3ff;
  font-size: 14px;
  margin: 24px 0 21px 21px;
}
::v-deep .el-tree {
  background: #03183f !important;
  color: #aed6ff;
}

::v-deep .el-tree-node {
  background: #03183f !important;
  color: #aed6ff;
}
::v-deep .is-current {
  background: #03183f !important;
  color: #ffc74a;
}

::v-deep .is-checked {
  background: #03183f !important;
  /* color: #FFC74A; */
}

::v-deep .el-tree-node__content:hover {
  background: #03183f;
  /* color: #FFC74A; */
}
::v-deep.el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background-color: #03183f;
}

::v-deep .el-tree-node {
  background-color: #03183f;
}
::v-deep .el-tree-node:focus > .el-tree-node__content {
  background-color: #03183f;
}
.main {
  width: 1376px;
  padding: 20px;
  box-sizing: border-box;
}
.title {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-right: 49px;
  display: flex;
  align-items: center;
}
.title img {
  width: 20px;
  height: 20px;
}
.title span {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-left: 10px;
}
/* 表格 */
.table{
  margin-top: 20px;
}
/* 设置表头的背景颜色 */
.table :deep() thead th {
  background-color: rgb(9, 35, 78);
  color: #e8f4ff;
  font-size: 14px;
}
.table :deep() tbody tr {
  font-size: 14px;
  color: #aed6ff;
}

/* 设置表格内的背景颜色 */
::v-deep.el-table tbody tr:nth-child(odd) {
  background-color: #071734;
}

::v-deep.el-table tbody tr:nth-child(even) {
  background-color: #071734;
}
::v-deep.el-table {
  border: 2px solid #081f42 !important;
  position: relative;
}

::v-deep.el-table th {
  border: 1px solid #09234e !important;
}
::v-deep.el-table td {
  border: 1px solid #0d2247 !important;
}
.table ::v-deep .el-table__body tr:hover > td {
  background-color: #081f42 !important;
}

::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table__fixed-right::before {
  height: 0px;
}
::v-deep .el-table__body tr.hover-row > td.el-table__cell {
  background-color: #081f42 !important;
}
/* 分页 */
.pagination {
  margin-top: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagination :deep() .el-pagination {
  padding-left: 0;
}
.home-page,
.tail-page {
  width: 60px;
  height: 30px;
  border-radius: 2px;
  border: 1px solid #0b2653;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
  color: #92b5da;
  text-align: center;
  line-height: 30px;
  margin-right: 5px;
}
.home-page:hover,
.tail-page:hover {
  background-color: #176ad2;
  box-sizing: border-box;
  color: #ffffff;
}
.home-page:checked,
.tail-page:checked {
  background-color: #176ad2;
  box-sizing: border-box;
  color: #ffffff;
}

.el-pagination >>> .btn-prev,
.el-pagination >>> .btn-next {
  width: 60px;
  height: 30px;
  border: 1px solid #0b2653;
  background-color: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  margin-right: 5px;
  line-height: 30px;
  padding: 0;
  text-align: center;
}
.el-pagination >>> .el-pager li {
  width: 35px;
  height: 30px;
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}
.jump-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-input {
  height: 30px;
  width: 35px;
  color: #92b5da;
  margin: 0 10px;
}
.pagination-input >>> .el-input__inner {
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #0b2653;
}
.pagination :deep().el-select .el-input__inner {
  height: 30px;

  color: #92b5da;
  border: 1px solid #0b2653;
  background-color: rgba(255, 255, 255, 0);
}
.pagination :deep() .el-pagination__sizes {
  margin-right: 0;
}

.jump-pagination .text {
  color: #92b5da;
  line-height: 30px;
}
.confirm {
  width: 60px;
  height: 30px;
  border-radius: 2px;
  border: 1px solid rgba(228, 228, 228, 0);
  background-color: #176ad2;
  font-family: "微软雅黑", sans-serif;
  color: #d7d7d7;
  text-align: center;
  line-height: 30px;
  padding: 0;
  margin-left: 5px;
}
</style>
